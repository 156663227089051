<template>
  <div class="list">
    <router-link
      :to="$route.path+'/video-detail/' + item.resources_guid + '/'+ item.resources_name"
      tag="div"
      class="item"
      v-for="(item, index) in videoList"
      :key="index"
    >
      <div class="item-img-block">
        <img class="item-img" :src="item.resources_cover" alt=""/>
      </div>
      <div class="desc">
        <div class="content">{{ item.resources_name }}</div>
        <div class="icon"></div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'VideoList',
  props: {
    videoList: {
      type: Array,
      default: null
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.list
  display flex
  flex-wrap wrap
  width 690px
  margin 44px auto 0 auto

  .item
    position relative
    width 336px
    height 240px
    border-radius 10px
    overflow hidden
    margin-bottom 20px

    &:nth-child(2n-1)
      margin-right 18px

    .item-img-block
      width 336px
      height 240px

      .item-img
        width 100%
        height 100%

    .desc
      position absolute
      bottom 0
      display flex
      align-items center
      width 336px
      height 62px
      background rgba(0, 0, 0, .6)

      .content
        padding-left 14px
        width 280px
        height 30px
        line-height 34px
        font-size 26px
        color rgba(255, 255, 255, 1)
        no-wrap()

      .icon
        width 30px
        height 30px
        bg-image('~@/assets/img/video/video-play')
        background-size 100% 100%
        background-repeat no-repeat
</style>
