<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="video">
      <video-header :nav-list="navList" @changeClass="changeClass"></video-header>
      <scroll
        ref="scroll"
        class="scroll"
        :data="videoList"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <video-swiper
            v-if="swiperList.length"
            :swiper-list="swiperList"
            :left-swiper="leftSwiper"
            :right-swiper="rightSwiper"
          ></video-swiper>
          <video-list :video-list="videoList"></video-list>
          <div class="home-bottom" v-show="showDivider">
            <divider class="divider">已加载全部内容</divider>
          </div>
        </div>
      </scroll>
      <router-view></router-view>
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex'
import Scroll from '@/components/scroll/scroll'
import VideoHeader from './components/Header'
import VideoSwiper from './components/Swiper'
import VideoList from './components/List'
import { Divider, Toast } from 'vant'
import { getClassList } from '@/api/Class'
import { getAdverts } from '@/api/Advert'
import { getResourcesPageList } from '@/api/Resources'

export default {
  name: 'Video',
  computed: {
    ...mapGetters([
      'agencyGuid',
      'openid'
    ])
  },
  components: {
    Scroll,
    VideoHeader,
    VideoSwiper,
    VideoList,
    Divider
  },
  mounted () {
    this._videoInit()
  },
  data () {
    return {
      isPullingDown: false,
      showDivider: false,
      categoryGuid: '', // 分类主键
      page: 1,
      limit: 10,
      navList: [], // 分类列表
      swiperList: [], // 轮播列表
      leftSwiper: [], // 左轮播
      rightSwiper: [], // 右轮播
      videoList: [] // 视频列表
    }
  },
  methods: {
    async _videoInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      await Promise.all([
        getClassList({
          category_type: 3
        }).then(res => {
          if (res.code === 200) {
            this.navList.splice(0, this.navList.length)
            this.navList = [
              {
                category_guid: '',
                category_name: '全部'
              }
            ]
            this.navList = this.navList.concat(res.data)
          }
        }),
        getAdverts({
          advert_type: 2,
          advert_agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            this.leftSwiper.push(res.data[res.data.length - 1])
            res.data.forEach((item, index) => {
              if (index !== res.data.length - 1) {
                this.leftSwiper.push(item)
              }
              if (index !== 0) {
                this.rightSwiper.push(item)
              }
            })
            this.rightSwiper.push(res.data[0])
            this.swiperList = res.data
          }
        }),
        this._getResourcesPageList()
      ])
      Toast.clear()
    },
    _getResourcesPageList () {
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: 3,
        category_guid: this.categoryGuid,
        page: this.page,
        limit: this.limit,
        field: this.$route.params.order,
        order: 'desc'
      }).then(res => {
        if (res.code === 200) {
          this.videoList = this.videoList.concat(res.data.list)
          if (res.data.count <= this.videoList.length) {
            this.showDivider = true
          }
        }
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    async pullDownRefresh () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      this.page = 1
      this.videoList.splice(0, this.videoList.length)
      this.showDivider = false
      await this._getResourcesPageList()
      Toast.clear()
    },
    async pullUpPush () {
      if (this.showDivider) {
        return true
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      this.page++
      await this._getResourcesPageList()
      Toast.clear()
    },
    async changeClass (categoryGuid) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      this.categoryGuid = categoryGuid
      this.page = 1
      this.videoList.splice(0, this.videoList.length)
      this.showDivider = false
      await this._getResourcesPageList()
      Toast.clear()
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.video
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 76px
    left 0
    right 0
    bottom 0
    overflow hidden

    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)

    .home-bottom
      wdith 750px
      height 70px
      padding-top 30px

      .divider
        width 300px
        height 22px
        font-size 22px
        color rgba(153, 153, 153, 1)
        margin 0 auto
        border-color rgba(153, 153, 153, 1)
</style>
